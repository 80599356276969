import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import { initiateCheckout, setAmountValue, sendStoreDataToServer, updateMetadata, getShopperContactInfo } from "../../app/paymentSlice";
import { getRedirectUrl } from "../../util/redirect";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import StateDropdown from './StateDropdown';
import { NumericFormat, PatternFormat } from 'react-number-format';
import ReCAPTCHA from 'react-google-recaptcha';
const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;





const type = "card";


export const PaymentContainer = () => {
  // const { type } = useParams();

  return (
    <div id="payment-page">
      <div className="container">
        <Checkout type={type} />
      </div>
    </div>
  );
}

const Checkout = () => {
  const dispatch = useDispatch();
  const payment = useSelector(state => state.payment);
  // Get the current amount value from the Redux store (optional)
  const currentAmount = useSelector((state) => state.payment.config.paymentMethodsConfiguration.card.amount.value);
  const [amount, setAmount] = useState(currentAmount);
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState('');
  const [confirmedEmail, setConfirmedEmail] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isRecaptchaReady, setIsRecaptchaReady] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    email: '',
    confirmedEmail: '',
    accountNumber: '',
    confirmedAccountNumber: '',
    nameOnContract: '',
    // amount: amount
  })
  
  // console.log(formData);

  console.log(payment)
 


  const handleAmountChange = (value) => {
    // console.log(value)
    setAmount(value); // Update local state for controlled input
    dispatch(updateMetadata(
      { 
        contractName: formData.nameOnContract, 
        contractNumber: formData.accountNumber,
        billingAddress: {
          address: formData.address,
          city: formData.city,
          state: formData.state,
          zip: formData.zip
        } }))
  };

  const navigate = useNavigate();

  const paymentContainer = useRef(null);
  // console.log(paymentContainer)

  // const { type } = useParams();

  useEffect(() => {
    dispatch(initiateCheckout(type));
  }, [dispatch])

  useEffect(() => {
    dispatch(setAmountValue(amount * 100));
    dispatch(sendStoreDataToServer());
    dispatch(getShopperContactInfo({ email: formData.email, phone: formData.phone }))

  }, [dispatch, amount])


  useEffect(() => {
    const { error } = payment;

    if (error) {
      console.log(error)
      // navigate(`/status/error?reason=${error}`, { replace: true });
    }
  }, [payment, navigate])

  useEffect(() => {
    // Dynamically load the reCAPTCHA script
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`;
    script.onload = () => setIsRecaptchaReady(true);
    document.head.appendChild(script);
  }, []);

  


  useEffect(() => {
    const { config, session } = payment;
    let ignore = false;

    if (!session || !paymentContainer.current) {
      // initiateCheckout is not finished yet.
      return;
    }

    const createCheckout = async () => {
      const checkout = await AdyenCheckout({
        ...config,
        session,
        onSubmit: (state, dropin) => {
          setErrorMsg('');
          handleValidationAndSubmit(state, dropin);
        },
        onPaymentCompleted: (response, _component) => {
          if (response.resultCode === "Authorised") {
            navigate(getRedirectUrl(response.resultCode), { replace: true });
          } else {
            setErrorMsg("The payment was refused. Please check the card details or try a different card.");
          }
        },
        onError: (error, _component) => {
          console.error(error);
          setErrorMsg("An error occurred. Please try again.");
        },
      });
    
      if (paymentContainer.current) {
        checkout.create(type).mount(paymentContainer.current);
      }
    };
    

    createCheckout();

    return () => {
      ignore = true;
    }
  }, [payment, currentAmount])

  const handleValidationAndSubmit = async (state, dropin) => {
    const isFormValid = validateForm();
    if (!isFormValid) {
      setErrorMsg("Please complete all required fields and ensure the details are correct.");
      setValidated(true);
      return;
    }
  
    try {
      const token = await new Promise((resolve) => {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(resolve);
        });
      });
  
      // Proceed with submission
      onSubmitHandler(state, dropin, token);
    } catch (error) {
      console.error("Validation failed:", error);
      setErrorMsg("Validation failed. Please try again.");
    }
  };
  
  const onSubmitHandler = async (state, dropin, token) => {
    if (!state.isValid) {
      setErrorMsg("Payment details are invalid. Please check your card information.");
      return;
    }
  
    try {
      const response = await fetch('/api/payments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          paymentMethod: state.data.paymentMethod,
          token,
          payment,
        }),
      });
  
      const result = await response.json();
      if (result.action) {
        dropin.handleAction(result.action);
      } else if (result.resultCode === 'Authorised') {
        navigate(getRedirectUrl(result.resultCode), { replace: true });
      } else {
        setErrorMsg("The payment was refused. Please check the card details or try a different card.");
      }
    } catch (error) {
      console.error("Payment error:", error);
      setErrorMsg("An error occurred while processing your payment. Please try again.");
    }
  };
  

  // const handleSubmit = () => {
  //   const isValid = validateForm(); // Custom validation logic
  //   console.log(isValid)
  //   if (!isValid) {
  //     console.log("Validation failed");
  //   } else {
  //     console.log("Validation succeeded");
  //     // Proceed with form submission or further processing
  //   }
  
  //   setValidated(true); // Update validation state for UI feedback
  // };
  
  function validateForm() {
    const formData = new FormData(document.querySelector('form'));
  
    let errors = [];
    setFormErrors();
  
    if (formData.get('firstname').trim() === '') {
      errors.push('First Name is required');
    }

    if (formData.get('lastname').trim() === '') {
      errors.push('Last Name is required');
    }

    if (formData.get('address').trim() === '') {
      errors.push('Address is required');
    }

    if (formData.get('city').trim() === '') {
      errors.push('City is required');
    }

    if (formData.get('state').trim() === '') {
      errors.push('State is required');
    }

    if (formData.get('zip').trim() === '') {
      errors.push('Zip is required');
    }

    if (formData.get('phone').trim() === '') {
      errors.push('Phone is required');
    }
  
    if (!/^\S+@\S+\.\S+$/.test(formData.get('email'))) {
      errors.push('Invalid email format');
    }

    if (!/^\S+@\S+\.\S+$/.test(formData.get('confirmedEmail'))) {
      errors.push('Invalid confirm email format');
    }

    if (formData.get('email').trim() !== formData.get('confirmedEmail')) {
      errors.push('Emails do not match');
    }

    if (formData.get('accountNumber').trim() === '') {
      errors.push('Account Number is required');
    }

    if (formData.get('confirmedAccountNumber').trim() === '') {
      errors.push('Confirm Account Number is required');
    }

    if (formData.get('accountNumber').trim() !== formData.get('confirmedAccountNumber').trim()) {
      errors.push('Account numbers do not match');
    }

    if (formData.get('nameOnContract').trim() === '') {
      errors.push('Name on contract is required');
    }
    

    
    if (errors.length > 0) {
      // Display errors to the user
      setFormErrors(errors)
      return false; // Prevent form submission
    }
  
    return true; // Form is valid
  }


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value.replace(/[^a-zA-Z0-9@' .]/g, ''),
    }));
  };
  

  return (
    <Container className="">
      <Row>
        <Col xs={12} md={9}>
          <p>
            Thank you for choosing Forest Lawn's online bill payment option. You can make your monthly payment instantly with no user name or passwords to remember. All you need is your information and a credit card.
          </p>
          <p>
            PLEASE NOTE: You will need your full account information, including your Account Number. The Account Number can be found on your monthly statement.
          </p>
          <p>
            Once you have your Account Number, simply enter the required information below and click SUBMIT. Our Bill Pay system will update your account within two (2) business days of your approved credit card payment. For your convenience, we accept the following methods of payment: Visa, MasterCard and Discover.
          </p>
          <p className="red-text">
            This Bill Pay feature only applies to advance planning accounts ("ahead of need" plans). Accounts that are considered at-need ("time of loss" plans) cannot use this feature at this time. To pay for "at-need" plans, please call the park's cashier at the number provided by your Memorial Counselor.
          </p>
          <p>
            If you have any questions, please call 1 (888) 204-3131 and ask for Client Accounts. We have staff available to assist you Monday through Friday, 8:00 am to 5:00 pm.
          </p>
          <p>
            Thank you again for choosing the quickest and easiest way to make a payment with Forest Lawn.
          </p>
        </Col>
        <Col xs={12} md={3}>
          <p>Call Us Directly<br></br>
          <a href="8882043131">(888) 204-3131</a></p>
        </Col>
      </Row>
      <Row>
        <Form noValidate validated={validated} id="billpay-form">
          <Stack gap={3} className="col-md-10">
            <div className="p-4 my-4">
              <p className="section-header">
                CARD HOLDER INFORMATION
              </p>
              <p className="required-text">
                All fields are required
              </p>
              <p className="form-text">
                Enter the card holder name as it appears on the front of the credit card.
              </p>
              <Form.Group as={Row} className="mb-3" controlId="Fname">
                <Form.Label column sm={2}>
                  First Name
                </Form.Label>
                <Col sm={10}>
                  <Form.Control name="firstname" maxLength={30} value={formData.firstname} onChange={handleInputChange} required />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="Lname">
                <Form.Label column sm={2}>
                  Last Name
                </Form.Label>
                <Col sm={10}>
                  <Form.Control  name="lastname" maxLength={30} value={formData.lastname} onChange={handleInputChange} required/>
                </Col>
              </Form.Group>
              <p className="red-text">
                For account verification, enter the address where you receive the bill for this credit card.
              </p>
              <Form.Group as={Row} className="mb-3" controlId="Address1">
                <Form.Label column sm={2}>
                  Address
                </Form.Label>
                <Col sm={10}>
                  <Form.Control placeholder="" name="address" maxLength={50} value={formData.address} onChange={handleInputChange} required />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="City">
                <Form.Label column sm={2}>
                  City
                </Form.Label>
                <Col sm={10}>
                  <Form.Control placeholder="" name="city" maxLength={50} value={formData.city} onChange={handleInputChange} required />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="State">
                <Form.Label column sm={2}>
                  State
                </Form.Label>
                <Col  sm={10}>
                  <StateDropdown value={formData.state} onChange={(e) => {
                    const selectedValue = e.target.value;
                    setFormData((prevData) => ({
                      ...prevData,
                      state: selectedValue,
                    }))
                  }} required />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="Zip">
                <Form.Label column sm={2}>
                  Zip
                </Form.Label>
                <Col sm={10}>
                  <Form.Control placeholder="" name="zip" minLength={5} maxLength={5} value={formData.zip} onChange={handleInputChange} required />
                </Col>
              </Form.Group>
              <p className="form-text">
                This information will be used if there is a question about your payment and 
                to send receipt confirmation. We do not sell contact information to third parties.
              </p>
              <Form.Group as={Row} className="mb-3" controlId="Phone">
                <Form.Label column sm={2}>
                  Phone
                </Form.Label>
                <Col sm={10}>
                  <PatternFormat className="form-control" name="phone" displayType="input" placeholder="(000) 000 - 0000" type="tel" value={formData.phone} format="(###) ### - ####" onValueChange={(values) => {
                    const { formattedValue } = values;
                      setFormData((prevData) => ({
                        ...prevData,
                        phone: formattedValue,
                      }));
                  }} required />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="Email">
                <Form.Label column sm={2}>
                  Email
                </Form.Label>
                <Col sm={10}>
                  <Form.Control 
                    type="email" 
                    placeholder=""
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    maxLength={250}
                    required
                    isInvalid={formData.confirmedEmail && formData.email !== formData.confirmedEmail} 
                  />
                  <Form.Control.Feedback type="invalid">
                    Emails do not match.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="ConfirmEmail">
                <Form.Label column sm={2}>
                  Confirm Email
                </Form.Label>
                <Col sm={10}>
                  <Form.Control 
                    type="email" 
                    name="confirmedEmail"
                    value={formData.confirmedEmail}
                    onChange={handleInputChange}
                    maxLength={250}
                    required
                    isInvalid={formData.confirmedEmail && formData.email !== formData.confirmedEmail}
                  />
                  <Form.Control.Feedback type="invalid">
                    Emails do not match.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </div>
            <div className="p-4 my-4">
              <p className="section-header">
                PURCHASER INFORMATION
              </p>
              <p className="required-text">
                All fields are required
              </p>
              <p className="form-text">
                Please pay only one contract at a time.
              </p>
              <Form.Group as={Row} className="mb-3" controlId="Account">
                <Form.Label column sm={3}>Account #</Form.Label>
                <Col sm={9}>
                  <Form.Control 
                    type="text"
                    name="accountNumber"
                    value={formData.accountNumber}
                    maxLength={50}
                    onChange={(e) => {
                      handleInputChange(e)
                      // dispatch(updateMetadata({ contractName: payment.metadata.contractName, contractNumber: formData.accountNumber }))                    
                    }}
                    required
                    isInvalid={formData.confirmedAccountNumber && formData.confirmedAccountNumber !== formData.accountNumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    Account Number must match the re-entered Account Number.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="ReenterAccount">
                <Form.Label column sm={3}>Re-enter Account #</Form.Label>
                <Col sm={9}>
                  <Form.Control 
                    type="text"
                    name="confirmedAccountNumber"
                    value={formData.confirmedAccountNumber}
                    maxLength={50}
                    onChange={(e) => {
                      
                      handleInputChange(e)
                    }}
                    required
                    isInvalid={formData.confirmedAccountNumber && formData.confirmedAccountNumber !== formData.accountNumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please ensure the Account Numbers match.
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid">
                    Account Numbers match.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="Contract">
                <Form.Label column sm={3}>Name on Contract</Form.Label>
                <Col sm={9}>
                  <Form.Control 
                    type="text"
                    name="nameOnContract"
                    value={formData.nameOnContract}
                    maxLength={50}
                    onChange={(e) => {
                      handleInputChange(e)
                      // const value = e.target.value;
                      // setFormData((prevData) => ({
                      //   ...prevData,
                      //   nameOnContract: value,
                      // }));
                      // dispatch(updateMetadata({ contractName: formData.nameOnContract, contractNumber: payment.metadata.contractNumber }))
                    }}
                    required
                  />
                </Col>
              </Form.Group>
            </div>
            <div className="p-4 my-4">
              <p className="section-header">
                CREDIT CARD INFORMATION
              </p>
              <p className="required-text">
                All fields are required
              </p>
              <p className="form-text">
                We accept Visa, MasterCard, and Discover.
              </p>
              <Form.Group as={Row} className="mb-3" controlId="Amount">
                <Form.Label column sm={2}>
                  Amount (USD):
                </Form.Label>
                <Col sm={10}>
                  <NumericFormat className="form-control" name="amount" allowNegative={false} min={1} maxLength={6} decimalScale={2} displayType="input" placeholder="0.00" value={formData.amount} format="#.##" onValueChange={(values) => {
                    const { formattedValue } = values;
                      handleAmountChange(formattedValue);
                  }} required />
                  <Form.Control.Feedback type="invalid">
                    Please enter an amount.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <div className="payment-container">
                <div ref={paymentContainer} className="payment"></div>
                <div className="my-2 text-center"><p style={{ color:"red" }}>{errorMsg}</p></div>
                <div className="form-errors">
                  <ul>
                  {formErrors && formErrors.map((error, index) => {
                    console.log(error)
                    return <li key={index} style={{ color:"red", listStyle: "circle" }}>{error}</li>
                  })}
                  </ul>
                </div>
              </div>
              <div id="recaptcha"></div>
              <hr></hr>
                <Row className="footer-text">
                  <Col xs={12} md={3}>
                    <p>Copyright @  2024 FOREST LAWN MEMORIAL-PARK ASSOCATION</p>
                  </Col>
                  <Col xs={12} md={9}>
                    <p className="text-center">Thank you for your interest in Forest Lawn.</p>
                    <p>We respect your privacy and will not sell your personal information. Forest Lawn will collect and use the information you provide 
                      here to periodically email, call or text you with information about products, services, and events according to the terms of the 
                      Forest Lawn <a href="https://forestlawn.com/privacy">Privacy Policy and Terms of Use</a> , until you change your communication preferences at <a href="https://forestlawn.com/preferences">www.forestlawn.com/preferences</a>.
                    </p>
                    <p>
                      FOREST LAWN MEMORIAL-PARKS & MORTUARIES
                    </p>
                  </Col>
                </Row>
              <hr></hr>
              <Row>
                  <Col xs={12} md={1}></Col>
                  <Col xs={12} md={10}>
                    <p className="footer-text">
                      ARCADIA FD 2186 | CATHEDRAL CITY FD 1847 | CITY OF INDUSTRY FD 2121 | COACHELLA FD 640 | COVINA HILLS FD 1150 | CYPRESS FD 1051
                      GLENDALE FD 656 | HOLLYWOOD HILLS FD 904 | INDIO FD 967 | LONG BEACH FD 1151 | MONROVIA FD 221 | SAN DIMAS FD 1783 | WHITTIER FD 2302
                    </p>
                  </Col>
                  <Col xs={12} md={1}></Col>
                </Row>
            </div>
          </Stack>
        </Form>
      </Row>
      
    </Container>
  );
}

export default Checkout;