import { createSlice } from "@reduxjs/toolkit";

const initialAmountValue = null;
// const initialAmountValue = parseInt(process.env.REACT_APP_INITIAL_AMOUNT_VALUE, 10);
console.log("clientKey: ", process.env.REACT_APP_ADYEN_CLIENT_KEY);

export const slice = createSlice({
  name: "payment",
  initialState: {
    error: "",
    session: null,
    orderRef: null,
    paymentDataStoreRes: null,
    config: {
      storePaymentMethod: true,
      paymentMethodsConfiguration: {
        ideal: {
          showImage: true,
        },
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          name: "Credit or debit card",
          brands: ['mc','visa','discover'],
          amount: {
            value: initialAmountValue, 
            currency: "USD",
          },
        },
      },
      locale: "en_US",
      showPayButton: true,
      clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
      environment: "live",
      analytics: {
        enabled: false, // Enable or disable analytics
      },
    },
    metadata: {
      contractName: "",
      contractNumber: "",
      billingAddress: null
    },    
  },
  reducers: {
    paymentSession: (state, action) => {
      const [res, status] = action.payload;
      if (status >= 300) {
        state.error = res;
      } else {
        [state.session, state.orderRef] = res;
      }
    },
    clearPaymentSession: (state) => {
      state.error = "";
      state.session = null;
      state.orderRef = null;
    },
    paymentDataStore: (state, action) => {
      const [res, status] = action.payload;
      if (status >= 300) {
        state.error = res;
      } else {
        state.paymentDataStoreRes = res;
      }
    },
    setAmountValue: (state, action) => {
      // Update only the amount.value field in minor units
      state.config.paymentMethodsConfiguration.card.amount.value = action.payload;
    },
    updateMetadata: (state, action) => {
      const { contractName, contractNumber, billingAddress } = action.payload;
      state.metadata.contractName = contractName;
      state.metadata.contractNumber = contractNumber;
      state.metadata.billingAddress = billingAddress;
    },
    getShopperContactInfo: (state, action) => {
      const { email, phone } = action.payload;
      state.shopperEmail = email;
      state.telephoneNumber = phone;
    }    
  },
});

export const { paymentSession, clearPaymentSession, paymentDataStore, setAmountValue, updateMetadata, getShopperContactInfo } = slice.actions;

export const initiateCheckout = (type) => async (dispatch) => {
  const response = await fetch(`/api/sessions?type=${type}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
  console.log(response);
  dispatch(paymentSession([await response.json(), response.status]));
};

export const getPaymentDataStore = () => async (dispatch) => {
  const response = await fetch("/api/getPaymentDataStore");
  dispatch(paymentDataStore([await response.json(), response.status]));
};

export const cancelOrRefundPayment = (orderRef) => async (dispatch) => {
  await fetch(`/api/cancelOrRefundPayment?orderRef=${orderRef}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
  dispatch(getPaymentDataStore());
};

export const sendStoreDataToServer = () => async (dispatch, getState) => {
  try {
    // Access the current state
    const state = getState();

    // Make an API call to send the Redux store data
    const response = await fetch("/api/sessions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(state), // Send the entire store
    });
    dispatch(paymentSession([await response.json(), response.status]));
   
  } catch (error) {
    console.error("Error sending data to server:", error);
  }
};

export default slice.reducer;
